export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Statistics',
    icon: 'ActivityIcon',
    children: [
      {
        title: 'Withdrawal statistics',
        icon: 'CreditCardIcon',
        route: 'withdrawal-statistics',
      },
      {
        title: 'Marketing statistics',
        icon: 'CreditCardIcon',
        route: 'marketing-statistics',
      },
      {
        title: 'Fin statistics',
        icon: 'CreditCardIcon',
        route: 'fin-statistics',
      },
      {
        title: 'Affiliate statistics',
        icon: 'CreditCardIcon',
        route: 'affiliate-statistics',
      },
    ],
  },
  {
    title: 'Affiliate',
    route: 'affiliate',
    icon: 'UsersIcon',
  },
  {
    title: 'Partners',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Partners',
        icon: 'UsersIcon',
        route: 'partner',
      },
      {
        title: 'Partners withdrawals',
        icon: 'CreditCardIcon',
        route: 'partner-withdrawals',
      },
      {
        title: 'Partners keys',
        icon: 'KeyIcon',
        route: 'partner-keys',
      },
      {
        title: 'Custom discounts',
        icon: 'PercentIcon',
        route: 'custom-discounts',
      },
    ],
  },
  {
    title: 'Users',
    route: 'users-beta',
    icon: 'UserIcon',
  },
  {
    title: 'Discounts',
    route: 'discounts-beta',
    icon: 'PercentIcon',
  },
  {
    title: 'Accounts',
    icon: 'KeyIcon',
    children: [
      {
        title: 'Account list',
        icon: 'KeyIcon',
        route: { name: 'accounts-beta' },
      },
      {
        title: 'Start new',
        icon: 'PlusIcon',
        route: { name: 'accounts-new' },
      },
      {
        title: 'Phases',
        route: { name: 'accounts-phases-beta' },
        icon: 'BarChartIcon',
      },
      {
        title: 'Waiting phases',
        route: { name: 'waiting-phases' },
        icon: 'BarChartIcon',
      },
    ],
  },
  {
    title: 'Orders',
    route: 'orders-beta',
    icon: 'FileIcon',
  },
  {
    title: 'Refunds',
    route: 'refunds',
    icon: 'PaperclipIcon',
  },
  {
    title: 'Tickets',
    route: 'tickets',
    icon: 'InboxIcon',
  },
  {
    title: 'Promos',
    route: 'promo',
    icon: 'ZapIcon',
  },
  {
    title: 'Tools',
    icon: 'ToolIcon',
    children: [
      {
        title: 'Economic calendar',
        icon: 'CalendarIcon',
        route: { name: 'tools-news' },
      },
      {
        title: 'Tags',
        icon: 'TagIcon',
        route: { name: 'tools-tags' },
      },
      {
        title: 'Announcements',
        icon: 'AlignLeftIcon',
        route: { name: 'tools-announcements' },
      },
      {
        title: 'Ip blacklist',
        icon: 'XOctagonIcon',
        route: { name: 'tools-blacklist' },
      },
      {
        title: 'Ip check',
        icon: 'XOctagonIcon',
        route: { name: 'tools-ip-check' },
      },
      {
        title: 'VPN logins',
        icon: 'XOctagonIcon',
        route: { name: 'tools-vpn-logins' },
      },
      {
        title: 'Risk score analysis',
        icon: 'XOctagonIcon',
        route: { name: 'tools-risk-score' },
      },
      {
        title: 'Account linkages',
        icon: 'KeyIcon',
        route: { name: 'tools-linkages' },
      },
      {
        title: 'Export data',
        icon: 'PaperclipIcon',
        route: { name: 'tools-exports' },
      },
    ],
  },
  {
    title: 'Trading history',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'Individual analysis',
        route: 'individual-analysis',
        icon: 'UserXIcon',
      },
      {
        title: 'Hedging analysis',
        route: 'hedging-analysis',
        icon: 'UserXIcon',
      },
    ],
  },
  {
    title: 'Agreements',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Agreements',
        route: 'agreement-beta',
        icon: 'FileTextIcon',
      },
      {
        title: 'Consent',
        route: 'contract-consent-beta',
        icon: 'CheckCircleIcon',
      },
    ],
  },
  {
    title: 'Withdrawals',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'All withdrawals',
        route: 'withdrawal-beta',
        icon: 'DollarSignIcon',
      },
      {
        title: 'Approved withdrawals',
        route: 'withdrawal-approved',
        icon: 'DollarSignIcon',
      },
    ],
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Country settings',
        route: 'settings-country',
        icon: 'KeyIcon',
      },
      {
        title: 'System roles',
        route: 'roles',
        icon: 'UsersIcon',
      },
      {
        title: 'Prime statistics',
        route: 'prime-statistics',
        icon: 'ZapIcon',
      },
    ],
  },
]
